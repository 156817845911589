import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ContactPerson from "../../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../../components/hero";
import { CaseList } from "../../../components/caseList.en";

import doodle from "../../../assets/images/services/projectmanagement.svg";

const ProjectManagementSupport = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-projectmanagement.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="en" langPath="service.projectmanagement.link">
      <Seo
        title="Agile project management and support– Monday Consulting"
        description="As project managers, we give you competent and collaborative support during your entire project – and afterwards, our support takes over to provide you with our expertise at any time."
        keywords="Agile, project management, ISO 27000-1, Scrum, Kanban, customer care, support, incident management"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Fast and reliable completion with modern project methods"
        subline="Agile project management and long-term care is our key to your success."
        section="Project management & support"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Project management & support"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="A single point of contact for your project"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>A single point of contact for your project</h2>
            <p>
              As project managers, we’re your first port of call for questions
              and requests. We provide you with competent and collaborative
              support during your entire project as well as access to our wealth
              of expertise at all times. Our interdisciplinary teams combine
              strategic, design and technical aspects to develop a solution that
              meets your objectives and guarantees your project’s long-term
              success.
            </p>
            <p>
              Typically, our developers will make use of agile methods like
              Scrum or Kanban. These aren’t mere add-ons, either: instead, our
              agile developers apply them to the full, supported and managed by
              certified Scrum/Agile Masters. One key aspect of agile methods is
              their focus on continuous adaptation within shared processes. By
              adopting this approach, we not only achieve an optimum end result
              but also establish resilient process flows and the long-term
              success of your project.
            </p>
            <p>
              We’re still there for you even after your website is up and
              running: our Customer Care team provides operational assistance,
              answers questions about your system, helps to resolve problems and
              also advises on the further development and editorial maintenance
              of your website.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Project management & support in detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-experienced bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Seasoned partners
            </h3>
            <p>
              We apply the expertise we have acquired in countless successful
              completions to ensure your project makes the grade. Thanks to our
              in-depth experience, we can identify and circumvent pitfalls and
              problems long before they occur. This gives you a faster
              time-to-market plus first-class results that benefit your business
              into the long term.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-knowhow bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Industry know-how
            </h3>
            <p>
              Our cooperation with enterprise clients from a wide range of
              sectors has given us a wealth of insider knowledge. By working
              with your internal IT to secure protection for sensitive data and
              ensuring we track requirements from employee representatives and
              data protection law, we develop a solution that fits your needs.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-incidentmanagement bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Incident management
            </h3>
            <p>
              You can report bugs or operational issues to our helpdesk at any
              time. Our support experts will then work closely with your team to
              resolve the problem.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-certified bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Certified security
            </h3>
            <p>
              Monday Consulting has ISO 27000-1 certification from TÜV Nord. As
              proven experts in information security compliance, we also make
              sure your project conforms to the required standards.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Selected case studies</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="haspa,lebensbaum" />
      </div>

      <ContactPerson
        headline="How can we help you today?"
        text="After browsing our impressive portfolio of services, why not contact us to find out more? Advice and assistance is just a phone call away."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Chief Executive"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default ProjectManagementSupport;
